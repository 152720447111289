import React from "react";
import ReactDOM from "react-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import DashboardLoadingView from "./views/DashboardLoadingView.js";
import DashboardLoginView from "./views/DashboardLoginView.js";
import DashboardHomeView from "./views/DashboardHomeView.js";
import DashboardTableView from "./views/DashboardTableView.js";

class Dashboard extends React.Component {
    constructor(properties) {
        super(properties);

        this.theme = createTheme({
            palette: {
                mode: "dark"
            }
        });

        this.state = {
            currentView: "LOADING"
        };
    }

    renderViews() {
        switch (this.state.currentView) {
            case "LOADING": return (
                <DashboardLoadingView />
            );

            case "LOGIN": return (
                <DashboardLoginView />
            );

            case "HOME": return (
                <DashboardHomeView />
            );

            case "TABLE": return (
                <DashboardTableView />
            );
        }
    }

    render() {
        return (
            <ThemeProvider theme={this.theme}>
                <CssBaseline />
                <Container maxWidth="lg" sx={{ minHeight: "100vh" }}>
                    <Box sx={{ paddingTop: 8, paddingBottom: 8, border: "1px dotted white" }}>
                        {this.renderViews()}
                    </Box>
                </Container>
            </ThemeProvider>
        );
    }
}

ReactDOM.createRoot(document.getElementById("root")).render(React.createElement(Dashboard));